import React from "react";
import { Route, Switch } from "react-router-dom";

import Scanner from "./pages/Scanner/Scanner";
import Home from "./pages/Home/Home";
import Bom from "./pages/Bom";
import CircuitDiagram from "./pages/CircuitDiagram";
import FeatureRouter from "./pages/Features";
import Session from "./pages/Session/Session";
import Cost from "./pages/Cost";
import ImageRouter from "./pages/Image/index";
import PrivateRoute from "./Components/PrivateRoute";
import IdeaRouter from "./pages/Idea/IdeaRouter";

function AppRouter() {
    return (
        <Switch>
            <Route exact path="/" component={Session} />

            <PrivateRoute exact path="/scanner" component={Scanner} />
            <PrivateRoute exact path="/home/:id" component={Home} />

            <PrivateRoute
                exact={false}
                path="/image/:id"
                component={ImageRouter}
            />
            <PrivateRoute exact path="/bomdata/:id" component={Bom} />
            <PrivateRoute exact path="/cost/:id" component={Cost} />
            <PrivateRoute
                exact
                path="/circuitdiagram/:id"
                component={CircuitDiagram}
            />
            <PrivateRoute
                exact={false}
                path="/feature/:id"
                component={FeatureRouter}
            />
             <PrivateRoute
                exact={false}
                path="/idea/:id"
                component={IdeaRouter}
            />
        </Switch>
    );
}

export default AppRouter;
