import React, { useState, useEffect, Fragment } from "react";
import s3Service from "../services/s3.service";
import logError from "../utils/logError";
import ImageLoader from "../utils/ImageLoader";
import loaderImage from "../assets/backgroundLazy.jpeg";

function ImageComponent({ relPath }) {
    const [s3Url, setS3Url] = useState(null);

    const fetchS3Url = async relPath =>
        s3Service.getS3Url({ relPath }).then(setS3Url).catch(logError);

    useEffect(() => {
        if (relPath) fetchS3Url(relPath);
    }, [relPath]);

    return (
        <Fragment>
            {s3Url && (
                <ImageLoader
                    src={s3Url}
                    width="170"
                    height="100"
                    placeholderImg={loaderImage}
                />
            )}
        </Fragment>
    );
}

export default ImageComponent;
{
    /* <img src={s3Url} width='170' height='100' /> */
}
