const IdeaTypeList = [
    "Alternate material",
    "Supplier Change",
    "Thickness Change",
    "Diameter Change",
    "Shape chnage Design change",
    "Mfg. Process Change",
    "Packaging Change",
    "joinery type change",
    "Styling change",
    "Routing change",
    "De-content",
    "Reduce change/fastner",
    "Coating thickness type",
    "Others",
];

export default IdeaTypeList;
