import circuitDiagramService from "../../services/circuitDiagram.service";
import { setError } from "./error.actions";

export const CIRCUIT_DIAGRAM = {
    FETCH_IMAGES_SUCCESS: "circuitDiagram/get/fulfilled",
};

const getCircuitDiagramImages = ( { vaultId, sessionId } ) => async dispatch => {
    try {
        const data = await circuitDiagramService.get( {
            vaultId,
            sessionId
        } );
        dispatch( {
            type: CIRCUIT_DIAGRAM.FETCH_IMAGES_SUCCESS,
            payload: data,
        } );
    } catch ( error ) {
        dispatch( setError( error ) );
    }
};

export { getCircuitDiagramImages };
