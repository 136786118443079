const SUBSYSTEM_BOUNDARY_INFO = [
    {
        abbreviation: "DA",
        name: "Door Assembly",
        boundary:
            "Door panels, ORVM, Door trims, Door lock, Door handle, Door beading,  Glass, Window mechanism, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "TA",
        name: "Tools & Accessories",
        boundary:
            "Tools, Screw jack, Warning triangle, screw jack lever and tools cover.",
    },
    {
        abbreviation: "SS",
        name: "Seating System",
        boundary:
            "Driver and Co driver seats, Seat belts, Seat belt locks, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "ET",
        name: "Exterior Trims",
        boundary:
            "Front bonnet, Bumper, Mud flaps, ,Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "IP",
        name: "Instrument Panel",
        boundary: "IP bar, IP panels, IP bar mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "WS",
        name: "Wiper System",
        boundary:
            "Wiper arm, Wiper blade, Wiper motor, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "IT",
        name: "Interior Trims",
        boundary:
            "Interior floor mats, Roof lining, A-pillar trim, IRVM, Document holder, Grab handle, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "HV",
        name: "HVAC",
        boundary: "Air ducts, Interior air vents and fasteners.",
    },
    {
        abbreviation: "PA",
        name: "Pedal Assembly",
        boundary:
            "Consists of Accelerator pedal, Brake pedal, Clutch pedal, Mounting brackets and Fasteners",
    },
    {
        abbreviation: "ST",
        name: "Steering System",
        boundary:
            "Steering wheel, Steering column, Steering column covering trims, Steering gear box, Steering link, Steering fluid, Reservoir ,Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "LB",
        name: "Load Body",
        boundary: "Consists of Load body and its mounting fasteners",
    },
    {
        abbreviation: "AI",
        name: "Air Intake",
        boundary:
            "Air filter, Air filter housing, Ducts, hoses, Snorkel, Filter service indicator, Mounting brackets, Resonator, Clamps, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "EX",
        name: "Exhaust",
        boundary:
            " Exhaust pipe, Ad blue tank and pump, Ad blue dosing unit, Muffler, Catalytic converter, mounting brackets, Ad blue hoses, Mounting brackets and fasteners.",
    },
    {
        abbreviation: "FS",
        name: "Fuel System",
        boundary:
            "Fuel tank, Fuel hoses, Fuel filter, ,Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "CA",
        name: "Clutch Actuation",
        boundary:
            "Clutch cable, Cable holder, Spring ,Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "PS",
        name: "Propeller Shaft",
        boundary:
            "Propeller shaft, Couplers, bearings, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "EC",
        name: "Engine Cooling",
        boundary:
            "Radiator, Engine coolant, Coolant hoses, Coolant reservoir, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "EN",
        name: "Engine",
        boundary:
            "Piston , Flywheel, Connecting rod, Crank shaft, Cylinder block, Drive belts, Pulleys, Oil sump, Engine casing, Valves, Oil pump, Coolant pump ,Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "EM",
        name: "Engine Mounts",
        boundary: "Consists of Engine mounts and Fasteners.",
    },
    {
        abbreviation: "TM",
        name: "Transmission",
        boundary:
            "Consists of Gear lever, Shifting rod, Gears, Gear box casing, Shift tower, Lay shaft, Main shaft, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "BA",
        name: "Brakes Assembly",
        boundary:
            "Brake booster, Brake fluid lines, Fluid reservoir, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "FA",
        name: "Front Axle",
        boundary:
            "Front axle, Tie rod, Anti roll bar, Disk brakes, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "RA",
        name: "Rear Axle",
        boundary:
            "Rear axle, Differential gears, Drum brakes, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "SP",
        name: "Suspension",
        boundary:
            "Suspension leaves, Dampers, U bolts ,Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "WT",
        name: "Wheel & Tyre",
        boundary: "Tyres,Rims,Spare wheel mounting and fasteners. ",
    },
    {
        abbreviation: "EL",
        name: "Electricals",
        boundary:
            " Wiring harnesses, Head light , Tail lamp, ECU, Fuse box, Switches, Sensors, Cabin light, Battery ,Horn, Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "CiW",
        name: "Cabin in White",
        boundary: "Cabin, Cabin rear window ,Mounting brackets  and Fasteners.",
    },
    {
        abbreviation: "CH",
        name: "Chassis",
        boundary: "Ladder frame , mounting brackets and fasteners",
    },
];

const getBoundaryInfo = subsystemAbbreviation =>
    SUBSYSTEM_BOUNDARY_INFO.find(
        el => el.abbreviation === subsystemAbbreviation
    )?.boundary || undefined;

const getSubsystemName = subsystemAbbreviation =>
    SUBSYSTEM_BOUNDARY_INFO.find(
        el => el.abbreviation === subsystemAbbreviation
    )?.name || undefined;

export { getBoundaryInfo, getSubsystemName };
