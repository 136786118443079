import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import image from "../../assets/image.svg";
import scanner from "../../assets/scanner.svg";
import {
    Link,
    useParams,
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";
import ImageCategory from "./ImageCategory";

import "../../styles/_module-navigation.scss";
import "../../styles/_image-repository.scss";
import ImagesView from "./ImagesView";

function ImageRouter() {
    const { id } = useParams();
    const { path, url } = useRouteMatch();

    return (
        <div className="image-repository">
            <div className="module-navigation">
                <Link exact to={`/home/${id}`}>
                    <HomeIcon className="module-navigation__home-icon" />
                </Link>

                <Link exact to={url}>
                    <img
                        src={image}
                        alt="Image Repository"
                        className="module-navigation__module-icon"
                    />
                </Link>

                <Link exact to="/scanner">
                    <img
                        src={scanner}
                        alt="error"
                        className="module-navigation__scan-icon"
                    />
                </Link>
            </div>
            <div className="image-repository__main">
                <Switch>
                    <Route exact path={path} component={ImageCategory} />
                    <Route
                        exact
                        path={`${path}/:category`}
                        component={ImagesView}
                    />
                </Switch>
            </div>
        </div>
    );
}

export default ImageRouter;
