import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import '../../styles/_feature-level.scss';

function FeatureLevel() {
	const { url } = useRouteMatch();

	return (
		<div className='feature-moduleSec'>
			<div className='feature-level'>
				<Link to={`${url}/1`}>
					<span className='feature-level__text'>Vehicle</span>
				</Link>

				<Link to={`${url}/2`}>
					<span className='feature-level__text'>Sub System</span>
				</Link>
			</div>
		</div>
	);
}

export default FeatureLevel;
