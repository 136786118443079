import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import circuitdiagram from "../../assets/circuitdiagram.svg";
import scanner from "../../assets/scanner.svg";
import { Link, useRouteMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";

import ImageCard from "../../Components/ImageCard";
import NoData from "../../Components/NoData";
import loadingImage from "../../assets/backgroundLazy.jpeg";
import ImageLoader from "../../utils/ImageLoader";

import { getCircuitDiagramImages } from "../../redux/actions/circuitDiagram.actions";

import "../../styles/_circuit-diagram.scss";

function CircuitDiagram() {
    const images = useSelector(state => state.circuitDiagram.images);

    const { id } = useParams();
    const { url } = useRouteMatch();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getCircuitDiagramImages({
                vaultId: id,
                sessionId: sessionStorage.getItem("sessionId"),
            })
        );
    }, [id]);

    const renderImageCard = image => (
        <ImageLoader
            key={image.id}
            src={image.image_url_cdn}
            placeholderImg={loadingImage}
            style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                margin: "10px",
            }}
        />
    );

    return (
        <div className="circuit-diagram">
            <div className="module-navigation">
                <Link exact to={`/home/${id}`}>
                    <HomeIcon className="module-navigation__home-icon" />
                </Link>

                <Link exact to={url}>
                    <img
                        src={circuitdiagram}
                        alt="Circuit Diagram"
                        className="module-navigation__module-icon"
                    />
                </Link>

                <Link exact to="/scanner">
                    <img
                        src={scanner}
                        alt="error"
                        className="module-navigation__scan-icon"
                    />
                </Link>
            </div>

            <div className="circuit-diagram__main">
                {!images.length && <NoData message="No images to display" />}
                {images.map(renderImageCard)}
            </div>
        </div>
    );
}

export default CircuitDiagram;
