import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import feature from '../../assets/feature.svg';
import Idea from '../../assets/idea-generation.svg';
import scanner from '../../assets/scanner.svg';
import {
	Link,
	useParams,
	Switch,
	Route,
	useRouteMatch,
} from 'react-router-dom';
import FeatureTypes from '../Features/FeatureTypes';

import FeatureLevel from '../Features/FeatureLevel';
import FeaturesList from '../Features/FeaturesList';
import FeatureDetails from '../Features/FeatureDetails';

// import "../../styles/_module-navigation.scss";
import '../../styles/_feature.scss';
import TableViews from './TableViews';
import IdeaDetails from './IdeaDetails';

function IdeaRouter() {
	const { id } = useParams();
	const { path, url } = useRouteMatch();

	return (
		<div className='feature'>
			<div className='module-navigation'>
				<Link exact to={`/home/${id}`}>
					<HomeIcon className='module-navigation__home-icon' />
				</Link>

				<Link exact to={url}>
					<img
						src={Idea}
						alt='Features'
						className='module-navigation__module-icon'
					/>
				</Link>

				<Link exact to='/scanner'>
					<img
						src={scanner}
						alt='error'
						className='module-navigation__scan-icon'
					/>
				</Link>
			</div>
			<div className='feature__main'>
				<Switch>
					<Route exact path={path} component={TableViews} />
					{/* <Route
                        exact
                        path={`${path}/:level`}
                        component={FeatureTypes}
                    /> */}
					<Route
						exact
						path={`${path}/:ideaId`}
						component={IdeaDetails}
					/>
				</Switch>
			</div>
		</div>
	);
}

export default IdeaRouter;
