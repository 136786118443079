import React from 'react';
import { isArray, isObject } from 'lodash';
import { TableCell, TableRow } from 'semantic-ui-react';
import '../../styles/_bom.scss';

function DataRow({ name, unit, value }) {
	const renderValue = () => {
		if (React.isValidElement(value)) return value;

		return isArray(value)
			? value.join(', ')
			: isObject(value)
			? '-'
			: value || '--';
	};

	return (
		<TableRow>
			<TableCell className='tableCell'>{name}</TableCell>
			<TableCell width='2'>{renderValue()}</TableCell>
			<TableCell width='2' className='tableCell'>
				{unit || '--'}
			</TableCell>
		</TableRow>
	);
}

export default DataRow;
