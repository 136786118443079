import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import { Table } from 'semantic-ui-react';

import bomdata from '../../assets/bomdata.svg';
import scanner from '../../assets/scanner.svg';

import { getBom } from '../../redux/actions/bom.actions';
import bomSelectors from '../../redux/selectors/bom.selectors';
import ImageComponent from '../../Components/ImageComponent';
import TableData from './TableData';
import DataRow from './DataRow';

import '../../styles/_bom.scss';

function Bom() {
	const { id } = useParams();
	const { url } = useRouteMatch();
	const dispatch = useDispatch();

	const values = useSelector(state => state.bom.values);

	const part_no = useSelector(state => state.bom.partno);

	const configs = useSelector(bomSelectors.selectNonTableConfigs);

	const tableConfigs = useSelector(bomSelectors.selectTableConfigs);

	configs.sort((x, y) => (x.name === 'Weight' ? -1 : y.name === 'Weight'));
	configs.sort((x, y) =>
		x.data_type === 'image' ? -1 : y.data_type === 'image'
	);

	useEffect(() => {
		dispatch(
			getBom({
				vaultId: id,
				sessionId: sessionStorage.getItem('sessionId'),
			})
		);
	}, []);

	const renderTableData = config => (
		<TableData
			key={config.id}
			config={config}
			values={values[config.id]?.[0] || {}}
		/>
	);

	return (
		<div className='bom'>
			<div className='module-navigation'>
				<Link exact to={`/home/${id}`}>
					<HomeIcon className='module-navigation__home-icon' />
				</Link>

				<Link exact to={url}>
					<img
						src={bomdata}
						alt='BOM'
						className='module-navigation__module-icon'
					/>
				</Link>

				<Link exact to='/scanner'>
					<img
						src={scanner}
						alt='error'
						className='module-navigation__scan-icon'
					/>
				</Link>
			</div>

			<div className='bom__main'>
				<div className='bom__table-container'>
					<Table celled unstackable className='bom__table'>
						<Table.Header>
							<Table.Row textAlign='center'>
								<Table.HeaderCell
									colSpan='3'
									className='tableHeader'>
									BOM Parameters
								</Table.HeaderCell>
							</Table.Row>

							<Table.Row textAlign='center'>
								<Table.HeaderCell className='tableHeader'>
									Parameter
								</Table.HeaderCell>
								<Table.HeaderCell className='tableHeader'>
									Value
								</Table.HeaderCell>
								<Table.HeaderCell className='tableHeader'>
									Unit
								</Table.HeaderCell>
							</Table.Row>
							<Table.Row textAlign='center'>
								<Table.HeaderCell
									colSpan='1'
									className='tableHeader'>
									Part No
								</Table.HeaderCell>
								<Table.HeaderCell
									colSpan='2'
									className='tableHeader'>
									{part_no}
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{configs.map(config =>
								config.data_type === 'image' ? (
									<DataRow
										key={config.id}
										name={config.name}
										unit={config.unit}
										value={
											<ImageComponent
												relPath={values[config.id]}
											/>
										}
									/>
								) : (
									<DataRow
										key={config.id}
										name={config.name}
										unit={config.unit}
										value={values[config.id]}
									/>
								)
							)}
						</Table.Body>
					</Table>
				</div>
				{tableConfigs.map(renderTableData)}
			</div>
		</div>
	);
}

export default Bom;
