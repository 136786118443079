import imageRepositoryService from "../../services/imageRepository.service";
import { setError } from "./error.actions";

export const IMAGE_REPOSITORY = {
    FETCH_IMAGES_SUCCESS: "imageRepository/get/fulfilled",
};

const getRepoImages = ( { vaultId, imageCategory, sessionId } ) => async dispatch => {
    try {
        const data = await imageRepositoryService.get( {
            vaultId,
            imageCategory,
            sessionId
        } );
        dispatch( {
            type: IMAGE_REPOSITORY.FETCH_IMAGES_SUCCESS,
            payload: data,
        } );
    } catch ( error ) {
        dispatch( setError( error ) );
    }
};

export { getRepoImages };
