// react

import axios from "axios";
import API from "../services/api.service";
import { s3Url } from "./s3url.utils";

const uploadimage = (
    fileList,
    type,
    setCurrentScenarioImage,
    setCompetitionScenarioImage,
    setCurrentScenarioImageUploadLoader,
    setCompetitionScebarioImageUploadModal
) => {
    // const file_name = file?.name;
    var random = Math.floor(Math.random() * 1000000 + 1);
    var editedImageName = `EditedImage${random}`;
    var uploadedImageString = "";
    // const dispatch = useAppDispatch();
    // + "-" + uuidv4();
    API.get(
        "cad/cad_upload/idea_form/",
        {
            module: 34,
            // product: top_vault == 0 ? vault : top_vault,
        },
        0
    )
        .then(res => res.json())
        .then(res => {
            const formData = new FormData();
            formData.append(
                "key",
                `${"IdeaForm"}/${"Vehicle"}/${type}/${random}/${
                    fileList.name || editedImageName
                }`
            );
            formData.append("Content-Type", fileList?.type);
            formData.append("x-amz-server-side-encryption", "AES256");
            formData.append("X-Amz-Credential", res?.data?.x_amz_credential);
            formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
            formData.append("X-Amz-Date", res?.data?.x_amz_date);
            formData.append("X-Amz-Signature", res?.data?.aws_policy_signature);
            formData.append("Policy", res?.data?.aws_policy);
            formData.append("file", fileList, fileList?.name);

            axios
                .post(s3Url, formData, {
                    onUploadProgress: data => {
                        //Set the progress value to show the progress bar
                    },
                })
                .then(async res => {
                    if (res.status === 204) {
                        uploadedImageString = `/${"IdeaForm"}/${"Vehicle"}/${type}/${random}/${
                            fileList.name || editedImageName
                        }`;
                        if (type === "current scenario") {
                            setCurrentScenarioImageUploadLoader(false)
                            setCurrentScenarioImage(
                                `/${"IdeaForm"}/${"Vehicle"}/${type}/${random}/${
                                    fileList.name || editedImageName
                                }`
                            );
                        } else {
                            setCompetitionScebarioImageUploadModal(false)
                            setCompetitionScenarioImage(
                                `/${"IdeaForm"}/${"Vehicle"}/${type}/${random}/${
                                    fileList.name || editedImageName
                                }`
                            );
                        }
                    }
                })
                .catch(err => {
                    setCurrentScenarioImageUploadLoader(false)
                    setCompetitionScebarioImageUploadModal(false)
                    uploadedImageString = "";
                });
        });
};

// ----------------------------------------------------------------------------------

export default uploadimage;
