import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import feature from '../../assets/feature.svg';
import scanner from '../../assets/scanner.svg';
import {
	Link,
	useParams,
	Switch,
	Route,
	useRouteMatch,
} from 'react-router-dom';
import FeatureTypes from './FeatureTypes';
import FeatureLevel from './FeatureLevel';
import FeaturesList from './FeaturesList';
import FeatureDetails from './FeatureDetails';
import '../../styles/_module-navigation.scss';
import '../../styles/_feature.scss';

function FeatureRouter() {
	const { id } = useParams();
	const { path, url } = useRouteMatch();

	return (
		<div className='feature'>
			<div className='module-navigation'>
				<Link exact to={`/home/${id}`}>
					<HomeIcon className='module-navigation__home-icon' />
				</Link>

				<Link exact to={url}>
					<img
						src={feature}
						alt='Features'
						className='module-navigation__module-icon'
					/>
				</Link>

				<Link exact to='/scanner'>
					<img
						src={scanner}
						alt='error'
						className='module-navigation__scan-icon'
					/>
				</Link>
			</div>
			<div className='feature__main'>
				<Switch>
					<Route exact path={path} component={FeatureLevel} />
					<Route
						exact
						path={`${path}/:level`}
						component={FeatureTypes}
					/>
					<Route
						exact
						path={`${path}/:level/:type`}
						component={FeaturesList}
					/>
					<Route
						exact
						path={`${path}/:level/:type/:featureId`}
						component={FeatureDetails}
					/>
				</Switch>
			</div>
		</div>
	);
}

export default FeatureRouter;
