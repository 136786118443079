import featureService from "../../services/feature.service";
import { setError } from "./error.actions";

export const FEATURE = {
    FETCH_FEATURES_SUCCESS: "feature/get/fulfilled",
    FETCH_FEATURE_DETAILS_SUCCESS: "feature/getById/fulfilled",
};

const getFeatures = ( {
    vaultId,
    isTopVault,
    featureType,
    sessionId
} ) => async dispatch => {
    try {
        const data = await featureService.get( {
            vaultId,
            featureType,
            isTopVault,
            sessionId
        } );
        dispatch( {
            type: FEATURE.FETCH_FEATURES_SUCCESS,
            payload: data,
        } );
    } catch ( error ) {
        dispatch( setError( error ) );
    }
};

const getFeatureById = ( { vaultId, featureId, sessionId } ) => async dispatch => {
    try {
        const data = await featureService.getById( { vaultId, featureId, sessionId } );
        dispatch( {
            type: FEATURE.FETCH_FEATURE_DETAILS_SUCCESS,
            payload: data,
        } );
    } catch ( error ) {
        dispatch( setError( error ) );
    }
};

export { getFeatures, getFeatureById };
