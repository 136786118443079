import React from "react";
import { Modal, Button, Divider } from "semantic-ui-react";

function GenerateIdeaModal({
    children,
    title,
    isOpen,
    onClose,
    basic = false,
}) {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            size="small"
            basic={basic}
            style={{ backgroundColor: "#ffffff" }}
        >
            {title && (
                <Modal.Header
                    style={{
                        color: "#0b3f95",
                    }}
                >
                    {title}
                </Modal.Header>
            )}
            <Divider style={{ margin: "8px 8px" }} />
            <Modal.Content
                style={{
                    color: "#0b3f95",

                    borderRadius: "10px",
                    overflowY: "scroll",
                    height: "32rem",
                }}
            >
                {children}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default GenerateIdeaModal;
