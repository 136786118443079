import API from "./api.service";

export default {
    get ( { vaultId, sessionId } ) {
        return API.get( "web_companion/comp_vault/", { vault: vaultId, session: sessionId } ).then(
            API.toJSON
        );
    },
    searchByPartNumber ( part_number, sessionId ) {
        return API.get( "web_companion/comp/vault/search/", {
            part_no: part_number,
            session: sessionId
        } ).then( API.toJSON );
    },
};
