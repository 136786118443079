import { hashHistory } from "../../App";
import authService from "../../services/auth.service";
import { setError } from "./error.actions";

export const AUTH = {
    LOGIN_SUCCESS: "auth/login/fulfilled",
    LOGOUT: "auth/logout",
};

const login = ( { sessionId } ) => async dispatch => {
    try {
        const data = await authService.login( { sessionId } );
        dispatch( {
            type: AUTH.LOGIN_SUCCESS,
            payload: data,
        } );
        hashHistory.push( "/scanner" );
    } catch ( error ) {
        dispatch( setError( error ) );
    }
};

const logout = () => dispatch => {
    dispatch( { type: AUTH.LOGOUT } );
    localStorage.clear();
    sessionStorage.clear();
    hashHistory.push( "/" );
};

export { login, logout };
