import React from "react";

const Footer = () => {
    return (
        <div className="application__footer">
            <a
                href="https://advancedstructures.in/"
                target="_blank"
                rel="noreferrer noopener"
            >
                Know more about ASI
            </a>
        </div>
    );
};

export default Footer;
