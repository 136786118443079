import { IMAGE_REPOSITORY } from "../actions/imageRepository.actions";

const initialState = {
    partInfo: {
        part_no: "",
        product_name: "",
        component_name: "",
        top_vault: 0,
        vault: 0,
    },
    images: [],
    error: null,
};

const ImageRepositoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case IMAGE_REPOSITORY.FETCH_IMAGES_SUCCESS:
            const { images, ...partInfo } = action.payload;
            return {
                partInfo,
                images,
                error: null,
            };

        default:
            return state;
    }
};

export { ImageRepositoryReducer, initialState as ImageRepositoryReducerInit };
