import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

function InfoModal({ children, title, isOpen, onClose, basic = false }) {
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			size='small'
			basic={basic}
			style={{ backgroundColor: '#ffffff', borderRadius: '10px' }}>
			{title && (
				<Modal.Header style={{ color: '#0b3f95' }}>
					{title}
				</Modal.Header>
			)}
			<Modal.Content style={{ color: '#0b3f95' }}>
				{children}
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>Close</Button>
			</Modal.Actions>
		</Modal>
	);
}

export default InfoModal;
