import API from "./api.service";

export default {
    get ( { vaultId, isTopVault, featureType, sessionId } ) {
        const body = {
            vault: vaultId,
            type: featureType,
            session: sessionId
        };

        if ( isTopVault ) body.is_top_vault = true;
        return API.get( "web_companion/comp/feature/", body ).then( API.toJSON );
    },
    getById ( { featureId, vaultId, sessionId } ) {
        return API.get( "web_companion/comp/feature/details/", {
            id: featureId,
            vault: vaultId,
            session: sessionId
        } ).then( API.toJSON );
    },
};
