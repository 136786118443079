import { AUTH } from "../actions/auth.actions";

const initialState = {
    user: null,
    error: null,
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH.LOGIN_SUCCESS:
            const { token, user } = action.payload;
            localStorage.setItem("token", token);
            return { user, error: null };

        default:
            return state;
    }
};

export { AuthReducer, initialState as AuthReducerInit };
