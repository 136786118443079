import { CIRCUIT_DIAGRAM } from "../actions/circuitDiagram.actions";

const initialState = {
    images: [],
    error: null,
};

const CircuitDiagramReducer = (state = initialState, action) => {
    switch (action.type) {
        case CIRCUIT_DIAGRAM.FETCH_IMAGES_SUCCESS:
            return {
                images: action.payload,
                error: null,
            };

        default:
            return state;
    }
};

export { CircuitDiagramReducer, initialState as CircuitDiagramReducerInit };
