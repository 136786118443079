import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import '../../styles/_feature-types.scss';

function FeatureTypes() {
	const { url } = useRouteMatch();

	return (
		<div className='feature_module'>
			<div className='feature-types'>
				<Link to={`${url}/0`}>
					<span className='feature-types__text'>Feature</span>
				</Link>
				<Link to={`${url}/1`}>
					<span className='feature-types__text'>Specification</span>
				</Link>
				<Link to={`${url}/2`}>
					<span className='feature-types__text'>Information</span>
				</Link>
			</div>
		</div>
	);
}

export default FeatureTypes;
