import { isBoolean } from 'lodash';
import React, { useRef } from 'react';
import {
	Button,
	Modal,
	Dropdown,
	DropdownOnSearchChangeData,
} from 'semantic-ui-react';
import vaultService from '../../services/vault.service';
import '../../styles/_search-modal.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from '../../redux/actions/error.actions';

function SearchModal({ isOpen, onClose = false }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const [selectedPart, setSelectedPart] = React.useState('');
	const [isFetchingOptions, setIsFetchingOptions] = React.useState(false);
	const [searchPartsOptions, setSearchVaultsOptions] = React.useState([]);
	const timeout = useRef(null);

	const handleDropdown = (e, data) => {
		data.options.map(dataItem => {
			if (dataItem.value === data.value) {
				setSelectedPart(dataItem);
			}
		});
	};

	const handleSearchChange = (e, DropdownOnSearchChangeData) => {
		if (DropdownOnSearchChangeData.searchQuery.length >= 7) {
			setIsFetchingOptions(true);
			clearTimeout(timeout.current);
			timeout.current = setTimeout(
				() =>
					vaultService
						.searchByPartNumber(
							DropdownOnSearchChangeData.searchQuery,
							sessionStorage.getItem('sessionId')
						)
						.then(partNumber => {
							const options = partNumber.map(p => ({
								key: p.id,
								text: p.part_no,
								value: p.name,
							}));
							setIsFetchingOptions(false);
							setSearchVaultsOptions(options);
						})
						.catch(error => {
							dispatch(setError(error));
						}),
				1000
			);
		} else {
			setIsFetchingOptions(false);
		}
	};

	const handleRouteChange = e => {
		if (selectedPart.key) {
			history.push(`/home/${selectedPart.key}`);
		}
	};

	return (
		<Modal
			centered={false}
			open={isOpen}
			onClose={onClose}
			onOpen={() => setOpen(true)}>
			<Modal.Header style={{ color: '#0b3f95' }}>
				Search Part Number
			</Modal.Header>
			<Modal.Content>
				<Dropdown
					placeholder='Select PartNumber'
					fluid
					search
					selection
					options={searchPartsOptions}
					onSearchChange={handleSearchChange}
					onChange={handleDropdown}
					loading={isFetchingOptions}
					selectOnBlur={false}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>Close</Button>
				<Button
					style={{ backgroundColor: '#0b3f95', color: 'white' }}
					className='primary'
					disabled={!selectedPart}
					onClick={handleRouteChange}>
					Apply
				</Button>
			</Modal.Actions>
		</Modal>
	);
}

export default SearchModal;
