import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";

function Toast({ text, onExited }) {
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => setIsOpen(false);

    useEffect(() => {
        if (text) setIsOpen(true);
    }, [text]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            message={text}
            open={isOpen}
            onClose={handleClose}
            autoHideDuration={3000}
            onExited={onExited}
            ClickAwayListenerProps={{
                onClickAway: event => event.stopPropagation(),
            }}
        />
    );
}

export default Toast;
