import bomService from "../../services/bom.service";
import { setError } from "./error.actions";

export const BOM = {
    FETCH_BOM_SUCCESS: "bom/get/fulfilled",
};

const getBom = ( { vaultId, sessionId } ) => async dispatch => {
    try {
        const data = await bomService.get( { vaultId, sessionId } );
        dispatch( {
            type: BOM.FETCH_BOM_SUCCESS,
            payload: data,
            session: sessionId
        } );
    } catch ( error ) {
        dispatch( setError( error ) );
    }
};

export { getBom };
