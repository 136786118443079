import { combineReducers } from "redux";

import { AuthReducer, AuthReducerInit } from "./auth.reducer";
import {
    ImageRepositoryReducer,
    ImageRepositoryReducerInit,
} from "./imageRepository.reducer";
import {
    CircuitDiagramReducer,
    CircuitDiagramReducerInit,
} from "./circuitDiagram.reducer";
import { CostReducer, CostReducerInit } from "./cost.reducer";
import { FeatureReducer, FeatureReducerInit } from "./feature.reducer";
import { BomReducer, BomReducerInit } from "./bom.reducer";
import { AUTH } from "../actions/auth.actions";
import { ErrorReducer, ErrorReducerInit } from "./error.reducer";

export const storeInitialState = {
    auth: AuthReducerInit,
    imageRepository: ImageRepositoryReducerInit,
    circuitDiagram: CircuitDiagramReducerInit,
    cost: CostReducerInit,
    feature: FeatureReducerInit,
    bom: BomReducerInit,
    error: ErrorReducerInit,
};

const reducer = combineReducers({
    auth: AuthReducer,
    imageRepository: ImageRepositoryReducer,
    circuitDiagram: CircuitDiagramReducer,
    cost: CostReducer,
    feature: FeatureReducer,
    bom: BomReducer,
    error: ErrorReducer,
});

const rootReducer = (state, action) => {
    if (action.type === AUTH.LOGOUT) {
        return storeInitialState;
    }

    return reducer(state, action);
};

export default rootReducer;
