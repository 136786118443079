import React from "react";
import { Modal } from "semantic-ui-react";
import QrReader from "react-qr-reader";
import { useHistory } from "react-router-dom";

import "../../styles/_scan-qr-modal.scss";
import vaultService from "../../services/vault.service";
import { useDispatch } from "react-redux";
import { setError } from "../../redux/actions/error.actions";

function ScanToGetPartDetails ( props ) {
    const { onClose, isOpen, setScanResult } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const sessionId = sessionStorage.getItem( "sessionId" );
    const handleScan = vaultId => {
        if ( !vaultId ) return;
        vaultService
            .get( { vaultId, sessionId } )
            .then( vault => {
                setScanResult( vault );
                onClose();
                // if (vault) history.push(`/home/${vault.id}`);
            } )
            .catch( error => {
                dispatch( setError( error ) );
            } );
    };

    return (
        <Modal
            open={ isOpen }
            onClose={ onClose }
            basic
            closeIcon
            className="scan-qr-modal"
        >
            <Modal.Header style={ { color: "#0b3f95" } }>Scan QR</Modal.Header>

            <Modal.Content>
                <div className="scan-qr-modal__content">
                    <QrReader
                        delay={ 300 }
                        onError={ () => { } }
                        onScan={ handleScan }
                        style={ { width: document.body.clientWidth - 100 } }
                    />
                </div>
            </Modal.Content>
        </Modal>
    );
}

export default ScanToGetPartDetails;
