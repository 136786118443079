import { Table } from "semantic-ui-react";
import React from "react";
import { useSelector } from "react-redux";
import { toFixed } from "../../utils/toFixed";
import "../../styles/_cost.scss";

function PartInfoTable() {
    const tag = useSelector(state => state.cost.tag);
    const total = useSelector(state => state.cost.total);
    const perUnitCost =
        total.material +
        total.process +
        total.overhead +
        total.children_cost_info;

    return (
        <div className="cost__table-container">
            <Table className="cost__table" unstackable celled>
                <Table.Header>
                    <Table.Row textAlign="center">
                        <Table.HeaderCell colSpan="2" className="tableHeader">
                            Part Info
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {Object.entries(tag).map(([key, value]) => (
                        <Table.Row key={key}>
                            <Table.Cell className="tableCell">{key}</Table.Cell>
                            <Table.Cell className="tableCell">
                                {value}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell className="tableCell">
                            Total Cost (INR)
                        </Table.HeaderCell>
                        <Table.HeaderCell className="tableCell">
                            {toFixed(perUnitCost)}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
}

export default PartInfoTable;
