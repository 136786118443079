import React from "react";
import { useSelector } from "react-redux";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { mapValues } from "lodash";

import { toFixed } from "../../utils/toFixed";

const Plot = createPlotlyComponent(Plotly);

function CostChart() {
    const { process, overhead, material, children_cost_info } = useSelector(
        state => mapValues(state.cost.total, toFixed)
    );
    const leafvalues = [material, process, overhead];
    const nonLeafValues = [material, process, overhead, children_cost_info];

    const leafNames = ["Raw Material Cost", "Process Cost", "Overhead Cost"];

    const nonLeafNames = [
        "Raw Material Cost",
        "Process Cost",
        "Overhead Cost",
        "Child Part Cost",
    ];

    const leafColors = ["#b6fb9a", "#faf578", "#f6c6ef"];

    const nonLeafColors = ["#b6fb9a", "#faf578", "#f6c6ef", "#ccf7fc"];

    return (
        <div className="cost__chart">
            <Plot
                data={[
                    {
                        values:
                            children_cost_info && children_cost_info > 0
                                ? nonLeafValues
                                : leafvalues,
                        labels:
                            children_cost_info && children_cost_info > 0
                                ? nonLeafNames
                                : leafNames,
                        marker: {
                            colors:
                                children_cost_info && children_cost_info > 0
                                    ? nonLeafColors
                                    : leafColors,
                        },
                        type: "pie",
                    },
                ]}
                layout={{
                    height: 180,
                    width: 350,
                    margin: {
                        l: 40,
                        t: 30,
                        r: 0,
                        b: 20,
                        pad: 100,
                    },
                }}
                config={{
                    displaylogo: false,
                }}
            />
        </div>
    );
}

export default CostChart;
