import { COST } from "../actions/cost.actions";

const initialState = {
    tag: {},
    total: {
        material: 0,
        process: 0,
        overhead: 0,
    },
    cost: {},
    partInfo: {},
    image: null,
    error: null,
};

const CostReducer = (state = initialState, action) => {
    switch (action.type) {
        case COST.FETCH_COST_SUCCESS:
            const {
                image,
                tag,
                bop,
                raw_material,
                overhead,
                labour,
                machine,
                setup,
                tool,
                children_cost_info,
                ...partInfo
            } = action.payload;
            return {
                image: action.payload.image,
                tag: action.payload.tag,
                partInfo,
                cost: {
                    bop: bop,
                    raw_material: raw_material,
                    overhead: overhead,
                    labour: labour,
                    machine: machine,
                    setup: setup,
                    tool: tool,
                    children_cost_info: Object.values(
                        children_cost_info
                    ).reduce((total, value) => total + value, 0),
                },
                total: {
                    material: bop + raw_material,
                    process: labour + machine + tool + setup,
                    overhead: overhead,
                    children_cost_info: Object.values(
                        children_cost_info
                    ).reduce((total, value) => total + value, 0),
                },
                error: null,
            };

        default:
            return state;
    }
};

export { CostReducer, initialState as CostReducerInit };
