import { ERROR } from "../actions/error.actions";

const initialState = { message: null };

const ErrorReducer = (state = initialState, action) => {
    switch (action.type) {
        case ERROR.SET:
            return {
                message: action.payload,
            };

        case ERROR.RESET:
            return initialState;

        default:
            return state;
    }
};

export { ErrorReducer, initialState as ErrorReducerInit };
