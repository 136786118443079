import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useParams, useRouteMatch, useHistory } from "react-router-dom";
// import { getFeatures } from "../../redux/actions/feature.actions";
import {
    Table,
    Button,
    Form,
    FormField,
    Divider,
    FormTextArea,
    Icon,
    Dropdown,
} from "semantic-ui-react";
import API from "../../services/api.service";
import NoData from "../../Components/NoData";
import GenerateIdeaModal from "../../Components/modals/GenerateIdeaModal";
import { useBaseModal } from "../../hooks/useBaseModal";
import ScanToGetPartDetails from "../../Components/modals/ScanToGetPartDetails";
import scanner from "../../assets/scanner.svg";
import IdeaTypeList from "../../constants/ClientIdea.constants";
import uploadimage from "../../utils/uploadimage";
import { useDispatch } from "react-redux";
import { setError } from "../../redux/actions/error.actions";
import vaultService from "../../services/vault.service";
import loader from "../../assets/loadergif.gif";

function TableViews() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const InputRef = useRef();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedPart, setSelectedPart] = React.useState({});
    const [selectedVault, setSelectedVault] = useState("");
    const [isFetchingOptions, setIsFetchingOptions] = React.useState(false);
    const [searchPartsOptions, setSearchVaultsOptions] = React.useState([]);
    const timeout = useRef(null);
    const [partNumber, setPartNumber] = useState("");
    const [partName, setPartName] = useState("");
    const [partWeight, setPartWeight] = useState("");
    const [ideaType, setIdeaType] = useState("");
    const [currentScenario, setCurrentScenario] = useState("");
    const [scanResult, setScanResult] = useState({});
    const [competitionScenario, setCompetitionScenario] = useState("");
    const [ideaDescription, setIdeaDescription] = useState("");
    const InputRefSecond = useRef();
    const infoModal = useBaseModal();
    const QRModal = useBaseModal();
    const [tableData, setTableData] = useState([]);
    const [currentScenarioImage, setCurrentScenarioImage] = useState("");
    const [competitionScenarioImage, setCompetitionScenarioImage] =
        useState("");
    const [
        currentScenarioImageUploadLoader,
        setCurrentScenarioImageUploadLoader,
    ] = useState(false);
    const [
        competitionScenarioImageUploadLoader,
        setCompetitionScebarioImageUploadModal,
    ] = useState(false);
    const [isShowButton, setIsShowButton] = useState(false);
    // const dispatch = useDispatch();
    // const isTopVault = useMemo(() => level === "1", [level]);
    // const dispatch = useDispatch();
    const history = useHistory();
    const { url, path } = useRouteMatch();
    const handleDropdown = (e, data) => {
        data.options.map(dataItem => {
            if (dataItem.value === data.value) {
                setSelectedPart(dataItem);
                setPartNumber(data.text);
                setSelectedVault(dataItem.value);
            }
        });
    };
    const handleSuccessMessage = message => {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: "success-toast", // Optional custom class for styling
        });
    };

    const handleSearchChange = (e, DropdownOnSearchChangeData) => {
        if (DropdownOnSearchChangeData.searchQuery.length >= 7) {
            setIsFetchingOptions(true);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(
                () =>
                    vaultService
                        .searchByPartNumber(
                            DropdownOnSearchChangeData.searchQuery,
                            sessionStorage.getItem("sessionId")
                        )
                        .then(partNumber => {
                            const options = partNumber.map(p => ({
                                key: p.name,
                                text: p.part_no,
                                value: p.id,
                                weight: p.weight,
                            }));
                            setIsFetchingOptions(false);
                            setSearchVaultsOptions(options);
                        })
                        .catch(error => {
                            dispatch(setError(error));
                        }),
                1000
            );
        } else {
            setIsFetchingOptions(false);
        }
    };
    useEffect(() => {
        if (Object.keys(scanResult)?.length > 0) {
            setPartNumber(scanResult?.part_no);
            setPartName(scanResult?.name);
            setPartWeight(scanResult?.weight);
            setSelectedVault(scanResult?.id);
        }
    }, [scanResult]);
    useEffect(() => {
        if (Object.keys(selectedPart)?.length > 0) {
            setPartName(selectedPart?.key);
            setPartWeight(selectedPart?.weight);
        }
    }, [selectedPart]);

    const handleOnChange = e => {
        if (e.target.name === "name") {
            setName(e.target.value);
        }
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
        if (e.target.name === "phone_no") {
            setPhone(e.target.value);
        }
        if (e.target.name === "part_number") {
            setPartNumber(e.target.value);
        }
        if (e.target.name === "part_name") {
            setPartName(e.target.value);
        }
        if (e.target.name === "part_weight") {
            setPartWeight(e.target.value);
        }
        if (e.target.name === "idea_type") {
            setIdeaType(e.target.value);
        }
        if (e.target.name === "current_scenario") {
            setCurrentScenario(e.target.value);
        }
        if (e.target.name === "competition_scenario") {
            setCompetitionScenario(e.target.value);
        }
        if (e.target.name === "idea_description") {
            setIdeaDescription(e.target.value);
        }
    };
    const handleChange = (e, type) => {
        const file = e.target.files?.[0];
        uploadimage(
            file,
            type,
            setCurrentScenarioImage,
            setCompetitionScenarioImage,
            setCurrentScenarioImageUploadLoader,
            setCompetitionScebarioImageUploadModal
        );
        if (type === "current scenario") {
            setCurrentScenarioImageUploadLoader(true);
        } else {
            setCompetitionScebarioImageUploadModal(true);
        }
        // saveImage(file, id);
        // setDisable( false );
    };
    const browserFilehandler = () => {
        return InputRef?.current?.click();
    };
    const browserFilehandlerSecond = () => {
        return InputRefSecond?.current?.click();
    };

    const redirectToSubsystem = () => {
        API.get("web_companion/comp/idea/", {
            vault: id,
            fetch: "subsystem",
            session: sessionStorage.getItem("sessionId"),
        })
            .then(res => res.json())
            .then(data => {
                setIsShowButton(true);
                setTableData(data);
            });
    };

    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        API.post("web_companion/comapanion_customer_idea_generation/", {
            name: name,
            email: email,
            phone: phone,
            vault_info: selectedVault,
            idea_type: ideaType,
            current_scenario: currentScenario,
            competition_scenario: competitionScenario,
            idea_description: ideaDescription,
            current_scenario_image: currentScenarioImage,
            competition_scenario_image: competitionScenarioImage,
        })
            .then(res => {
                setName("");
                setPhone("");
                setEmail("");
                setSelectedVault("");
                setIdeaType("");
                setCurrentScenario("");
                setCompetitionScenario("");
                setCurrentScenarioImage("");
                setCompetitionScenarioImage("");
                setCurrentScenarioImageUploadLoader(false);
                setCompetitionScebarioImageUploadModal(false);
                setScanResult({});
                setSelectedPart({});
                res.json();
                dispatch(setError("Idea created Successfully"));
                infoModal.close();
            })
            .catch(err => console.log(err));
    };
    const getTableData = () => {
        API.get("web_companion/comp/idea/", {
            vault: id,
            session: sessionStorage.getItem("sessionId"),
        })
            .then(res => res.json())
            .then(data => {
                if (data?.length > 0) {
                    setIsShowButton(true);
                } else {
                    setIsShowButton(false);
                }

                setTableData(data);
            });
    };
    useEffect(() => {
        getTableData();
    }, []);

    return (
        <div className="idea__table-container">
            <ScanToGetPartDetails
                isOpen={QRModal.isOpen}
                onClose={QRModal.close}
                setScanResult={setScanResult}
            />
            <GenerateIdeaModal
                isOpen={infoModal.isOpen}
                onClose={infoModal.close}
                title="xcPEP Idea Form"
                basic
            >
                <div>
                    <Form onSubmit={e => handleSubmit(e)}>
                        <FormField>
                            <label>
                                Name <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                placeholder="Name"
                                name="name"
                                required
                                onChange={e => handleOnChange(e)}
                            />
                        </FormField>
                        <FormField>
                            <label>
                                Email ID <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                name="email"
                                placeholder="Email id"
                                required
                                onChange={e => handleOnChange(e)}
                            />
                        </FormField>
                        <FormField>
                            <label>
                                Phone Number{" "}
                                <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                name="phone_no"
                                placeholder="Phone No."
                                onChange={e => handleOnChange(e)}
                                required
                            />
                        </FormField>
                        <Divider />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <label>Scan QR to Autofill Part Details</label>
                            <img
                                src={scanner}
                                alt="error"
                                className="module-navigation__scan-icon"
                                onClick={() => QRModal.open()}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                        <Divider />
                        <FormField>
                            <label>
                                Part Number{" "}
                                <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <Dropdown
                                placeholder={
                                    Object.keys(scanResult)?.length > 0
                                        ? partNumber
                                        : "Select PartNumber"
                                }
                                fluid
                                search
                                selection
                                value={+selectedVault}
                                options={searchPartsOptions}
                                onSearchChange={handleSearchChange}
                                onChange={handleDropdown}
                                loading={isFetchingOptions}
                                selectOnBlur={false}
                            />
                        </FormField>
                        <FormField>
                            <label>
                                Part Name <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                name="part_name"
                                value={partName}
                                readOnly={true}
                                placeholder="part name"
                                onChange={e => handleOnChange(e)}
                                required
                            />
                        </FormField>
                        <FormField>
                            <label>
                                Part Weight{" "}
                                <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                type="number"
                                name="part_weight"
                                value={partWeight}
                                readOnly={true}
                                placeholder="part weight."
                                onChange={e => handleOnChange(e)}
                                required
                            />
                        </FormField>
                        <FormField>
                            <label>
                                Select Idea types{" "}
                                <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <select
                                name="idea_type"
                                multiple=""
                                onChange={e => handleOnChange(e)}
                                value={ideaType}
                                class="ui fluid dropdown"
                                required
                            >
                                {IdeaTypeList?.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </FormField>
                        <FormField
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <FormTextArea
                                label="Current Scenario"
                                name="current_scenario"
                                onChange={e => handleOnChange(e)}
                                placeholder="write current scenario..."
                                style={{ width: "20rem" }}
                                required={true}
                            />
                            {!currentScenarioImageUploadLoader ? (
                                <Icon
                                    name="camera retro"
                                    link
                                    style={{
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                        color:
                                            currentScenarioImage?.length > 0
                                                ? "green"
                                                : "",
                                    }}
                                    onClick={() => browserFilehandler()}
                                />
                            ) : (
                                <div style={{ width: "3rem", height: "3rem" }}>
                                    <img src={loader} />{" "}
                                </div>
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                id="files"
                                name="files"
                                capture="user"
                                style={{ display: "none" }}
                                onChange={e =>
                                    handleChange(e, "current scenario")
                                }
                                ref={InputRef}
                                multiple
                            />
                        </FormField>
                        <FormField
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <FormTextArea
                                label="Competition Scenario"
                                name="competition_scenario"
                                required={true}
                                onChange={e => handleOnChange(e)}
                                placeholder="write competition scenario..."
                                style={{ width: "20rem" }}
                            />
                            {!competitionScenarioImageUploadLoader ? (
                                <Icon
                                    name="camera retro"
                                    link
                                    style={{
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                        color:
                                            competitionScenarioImage?.length > 0
                                                ? "green"
                                                : "",
                                    }}
                                    onClick={() => browserFilehandlerSecond()}
                                />
                            ) : (
                                <div style={{ width: "3rem", height: "3rem" }}>
                                    <img src={loader} />{" "}
                                </div>
                            )}
                            <input
                                type="file"
                                id="files"
                                accept="image/*"
                                name="files"
                                style={{ display: "none" }}
                                capture="user"
                                onChange={e =>
                                    handleChange(e, "competitor scenario")
                                }
                                ref={InputRefSecond}
                                multiple
                            />
                        </FormField>
                        <FormField>
                            <FormTextArea
                                label="Idea Description"
                                name="idea_description"
                                onChange={e => handleOnChange(e)}
                                placeholder="write idea description..."
                            />
                        </FormField>

                        <Button type="submit">Submit</Button>
                    </Form>
                </div>
            </GenerateIdeaModal>
            {!isShowButton && (
                <Button
                    className="subSysbtn"
                    onClick={() => redirectToSubsystem()}
                ></Button>
            )}
            <Button className="subSysbtn" onClick={() => infoModal.open()}>
                Generate Idea +
            </Button>
            <Table unstackable className="idea__table">
                <Table.Header style={{ position: "sticky" }}>
                    <Table.Row textAlign="center">
                        <Table.HeaderCell className="_headerColumn">
                            Idea ID
                        </Table.HeaderCell>
                        <Table.HeaderCell className="_headerColumn">
                            Saving value (INR)
                        </Table.HeaderCell>
                        <Table.HeaderCell className="_headerColumn">
                            Status
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {tableData.length > 0 ? (
                        tableData?.map((item, index) => {
                            return (
                                <Table.Row
                                    key={index}
                                    style={{ border: "1px solid #e8f0fe" }}
                                >
                                    <Table.Cell
                                        className="_rowColumn"
                                        textAlign="center"
                                        onClick={() =>
                                            history.push(`${url}/${item.id}`)
                                        }
                                        style={{
                                            cursor: "pointer",
                                            color: "#0b3f95",
                                            border: "1px solid #e8f0fe",
                                            padding: "0.2rem 0.5rem",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        {item?.idea_id}
                                    </Table.Cell>
                                    <Table.Cell
                                        className="_rowColumn"
                                        textAlign="center"
                                    >
                                        {item?.cost}
                                    </Table.Cell>
                                    <Table.Cell
                                        className="_rowColumn"
                                        textAlign="center"
                                    >
                                        {item?.idea_status}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })
                    ) : (
                        <Table.Row>
                            <Table.Cell className="_rowColumn" colSpan={3}>
                                <NoData message={"No Data"} />
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </div>
    );
}

export default TableViews;
