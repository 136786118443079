import API from "./api.service";

const url = "web_companion/comp/image_repo/";

export default {
    get ( { vaultId, imageCategory, sessionId } ) {
        return API.get( url, {
            vault: vaultId,
            image_category: imageCategory,
            session: sessionId
        } ).then( API.toJSON );
    },
};
