import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import XCPEPLogo from '../../assets/xcPEPLogo.png';
import XCPEP from '../../assets/xcpeplower.png';

import { login } from '../../redux/actions/auth.actions';
import isAuthenticated from '../../utils/isAuthenticated';

import '../../styles/_session.scss';

function Session() {
	const dispatch = useDispatch();

	const [OTP, setOTP] = useState('');

	const handleChange = session_id => setOTP(session_id);

	const handleClicked = () => {
		sessionStorage.setItem('sessionId', OTP);
		dispatch(login({ sessionId: OTP }));
	};

	if (isAuthenticated()) return <Redirect to='/scanner' />;
	return (
		<div className='session'>
			<div className='session__content'>
				<span className='session__page'>
					<span className='session__page__infoboxActive'></span>
					<span className='session__page__infobox'></span>
				</span>
				<span className='session__logo-container'>
					<img
						src={XCPEPLogo}
						alt='xcPEP Logo'
						className='img'
						style={{ width: '10rem' }}
					/>
				</span>
				<div className='session__otp_wrapper'>
					<h4 className='session__header'>Enter Session ID​</h4>
					<span className='session__otp-container'>
						<OtpInput
							className='session__otp-container__input'
							value={OTP}
							onChange={handleChange}
							numInputs={6}
							separator={<span>&nbsp;</span>}
							isInputNum={true}
							isInputSecure={true}
						/>
					</span>

					<div className='session__button-container'>
						<button
							className='session__button'
							onClick={handleClicked}>
							OK
						</button>
					</div>
				</div>
			</div>

			{/* <div className='session__image-container'>
				<img src={XCPEP} alt='xcPEP Logo' className='img' />
			</div> */}
		</div>
	);
}

export default Session;
