import { createSelector } from "reselect";

const selectBomConfigs = state => state.bom.configs;

const selectNonTableConfigs = createSelector(selectBomConfigs, configs =>
    configs.filter(
        config =>
            config.data_type !== "table" && config.data_type !== "percentage"
    )
);

const selectTableConfigs = createSelector(selectBomConfigs, configs =>
    configs.filter(config => config.data_type === "table")
);

const selectTableColumnConfigs = createSelector(
    selectBomConfigs,
    (_, tableId) => tableId,
    (configs, tableId) => configs.filter(config => config.table_id === tableId)
);

export default {
    selectNonTableConfigs,
    selectTableConfigs,
    selectTableColumnConfigs,
};
