import React, { useState, useEffect } from "react";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import { Table, Accordion, Icon } from "semantic-ui-react";
import API from "../../services/api.service";
import NoData from "../../Components/NoData";
import styles from "../../styles/_idea.scss";
import ImageLoader from "../../utils/ImageLoader";
import loadingImage from "../../assets/backgroundLazy.jpeg";

function IdeaDetails() {
    const [activeIndex, setActiveIndex] = useState(0);
    const { id, ideaId } = useParams();
    const history = useHistory();
    const [ideaDetails, setIdeaDetails] = useState([]);
    const handleClick = (e, props) => {
        const { index } = props;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    const prevClick = () => {
        if (ideaDetails[0]?.prev_idea !== null) {
            history.push(`/idea/${id}/${ideaDetails[0]?.prev_idea}`);
            getTableDetailsDataonPageChange(ideaDetails[0]?.prev_idea);
        }
    };
    const nextClick = () => {
        if (ideaDetails[0]?.next_idea !== null) {
            history.push(`/idea/${id}/${ideaDetails[0]?.next_idea}`);
            getTableDetailsDataonPageChange(ideaDetails[0]?.next_idea);
        }
    };

    const getTableDetailsData = () => {
        API.get("web_companion/comp/idea/data/", {
            vault: id,
            id: ideaId,
            session: sessionStorage.getItem("sessionId"),
        })
            .then(res => res.json())
            .then(data => setIdeaDetails(data));
    };
    const getTableDetailsDataonPageChange = idea_id => {
        API.get("web_companion/comp/idea/data/", {
            vault: id,
            id: idea_id,
            session: sessionStorage.getItem("sessionId"),
        })
            .then(res => res.json())
            .then(data => setIdeaDetails(data));
    };
    useEffect(() => {
        getTableDetailsData();
    }, []);
    const getValue = val => {
        switch (val) {
            case true:
                return "YES";
            case false:
                return "NO";
            default:
                return val;
        }
    };

    return (
        <div className="idea__table-container">
            <div className="ideaHeader">
                <div className="sliderIcon">
                    <Icon
                        name="caret left"
                        style={
                            ideaDetails[0]?.prev_idea == null
                                ? {
                                      marginLeft: "-0.25rem",
                                      cursor: "not-allowed",
                                      color: "#DEDEDE",
                                  }
                                : {
                                      marginLeft: "-0.25rem",
                                      cursor: "pointer",
                                      color: "#FFFFFF",
                                  }
                        }
                        onClick={() => prevClick()}
                        link
                        disabled={
                            ideaDetails[0]?.prev_idea == null ? true : false
                        }
                    />
                </div>
                <div>
                    <span>{ideaDetails[0]?.idea_id}</span>
                </div>
                <div className="sliderIcon">
                    <Icon
                        name="caret right"
                        style={
                            ideaDetails[0]?.prev_idea == null
                                ? { cursor: "not-allowed", color: "#DEDEDE" }
                                : { cursor: "pointer", color: "#FFFFFF" }
                        }
                        onClick={() => nextClick()}
                        link
                        disabled={
                            ideaDetails[0]?.next_idea == null ? true : false
                        }
                    />
                </div>
            </div>
            <Table unstackable className="idea__table">
                <Table.Header style={{ position: "sticky" }}>
                    <Table.Row textAlign="center">
                        <Table.HeaderCell className="_headerColumn">
                            ECS (INR)
                        </Table.HeaderCell>
                        <Table.HeaderCell className="_headerColumn">
                            EWS (Kg)
                        </Table.HeaderCell>
                        <Table.HeaderCell className="_headerColumn">
                            Idea Type:
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {ideaDetails?.length > 0 && (
                        <Table.Row style={{ border: "1px solid #e8f0fe" }}>
                            <Table.Cell className="_rowColumn">
                                {ideaDetails[0]?.cost}
                            </Table.Cell>
                            <Table.Cell className="_rowColumn">
                                {ideaDetails[0]?.weight}
                            </Table.Cell>
                            <Table.Cell className="_rowColumn">
                                {ideaDetails[0]?.idea_type}
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            <Accordion>
                {ideaDetails &&
                    ideaDetails?.length > 0 &&
                    Object.keys(ideaDetails[0]?.parameter_json_data)?.map(
                        (item, index) => {
                            return (
                                <>
                                    <Accordion.Title
                                        active={activeIndex === index}
                                        index={index}
                                        onClick={handleClick}
                                    >
                                        <Icon name="add" />
                                        {item}
                                    </Accordion.Title>
                                    <Accordion.Content
                                        active={activeIndex === index}
                                    >
                                        <p>
                                            {getValue(
                                                ideaDetails[0]
                                                    ?.parameter_json_data[item]
                                            )}
                                        </p>
                                    </Accordion.Content>
                                </>
                            );
                        }
                    )}
            </Accordion>
            <Table unstackable className="idea__table">
                <Table.Header>
                    <Table.Row textAlign="center">
                        <Table.Cell className="_headerColumn left">
                            Parameters
                        </Table.Cell>
                        <Table.Cell className="_headerColumn right">
                            Value
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {ideaDetails && ideaDetails?.length > 0 ? (
                        Object.keys(ideaDetails[0]?.parameter_json_image)?.map(
                            (item, index) => {
                                return (
                                    <Table.Row
                                        style={{ border: "1px solid #e8f0fe" }}
                                    >
                                        <Table.Cell
                                            className="_rowColumn left"
                                            width="40%"
                                            textAlign="left"
                                        >
                                            {item}
                                        </Table.Cell>
                                        <Table.Cell
                                            className="_rowColumn right"
                                            textAlign="center"
                                        >
                                            <ImageLoader
                                                src={
                                                    ideaDetails[0]
                                                        ?.parameter_json_image[
                                                        item
                                                    ]
                                                }
                                                placeholderImg={loadingImage}
                                                style={{
                                                    width: "10rem",
                                                    height: "7rem",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            }
                        )
                    ) : (
                        <NoData message={"No Data"} />
                    )}
                </Table.Body>
            </Table>
        </div>
    );
}

export default IdeaDetails;
