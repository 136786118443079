import React from "react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import { toFixed } from "../../utils/toFixed";

function CostTable() {
    const cost = useSelector(state => state.cost.cost);
    const total = useSelector(state => state.cost.total);
    const partInfo = useSelector(state => state.cost.part_info);
    const perUnitCost =
        total.material +
        total.process +
        total.overhead +
        total.children_cost_info;
    const getContributionPercentageByValue = value => {
        if (!value) return 0;
        const percentage = (value / perUnitCost) * 100;
        return toFixed(percentage);
    };

    const getContributionPercentage = key => {
        const value = cost[key];
        if (typeof value !== "number") return 0;
        if (perUnitCost === 0) return 0;
        const percentage = (value / perUnitCost) * 100;
        return toFixed(percentage);
    };

    const getValue = key => {
        const value = cost[key];
        if (typeof value !== "number") return 0;
        return toFixed(value);
    };

    return (
        <div className="cost__table-container">
            <Table celled unstackable className="cost__table">
                <Table.Header>
                    <Table.Row textAlign="center">
                        <Table.HeaderCell colSpan="3" className="tableHeader">
                            Cost Drivers
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row active>
                        <Table.Cell className="tableCell">Material</Table.Cell>
                        <Table.Cell className="tableCell">
                            {toFixed(total?.material)}
                        </Table.Cell>
                        <Table.Cell className="tableCell">
                            {getContributionPercentageByValue(total?.material)}%
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className="tableCell">
                            Raw Material
                        </Table.Cell>
                        <Table.Cell className="tableCell">
                            {getValue("raw_material")}
                        </Table.Cell>
                        <Table.Cell className="tableCell">
                            {" "}
                            {getContributionPercentage("raw_material")}%
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className="tableCell">BOP</Table.Cell>
                        <Table.Cell className="tableCell">
                            {getValue("bop")}
                        </Table.Cell>
                        <Table.Cell className="tableCell">
                            {" "}
                            {getContributionPercentage("bop")}%
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row active>
                        <Table.Cell className="tableCell">Process</Table.Cell>
                        <Table.Cell className="tableCell">
                            {toFixed(total?.process)}
                        </Table.Cell>
                        <Table.Cell className="tableCell">
                            {getContributionPercentageByValue(total?.process)}%
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className="tableCell">Labour</Table.Cell>
                        <Table.Cell className="tableCell">
                            {getValue("labour")}
                        </Table.Cell>
                        <Table.Cell className="tableCell">
                            {getContributionPercentage("labour")}%
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell className="tableCell">Machine</Table.Cell>
                        <Table.Cell className="tableCell">
                            {getValue("machine")}
                        </Table.Cell>
                        <Table.Cell className="tableCell">
                            {getContributionPercentage("machine")}%
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className="tableCell">Setup</Table.Cell>
                        <Table.Cell className="tableCell">
                            {getValue("setup")}
                        </Table.Cell>
                        <Table.Cell className="tableCell">
                            {getContributionPercentage("setup")}%
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className="tableCell">Tool</Table.Cell>
                        <Table.Cell className="tableCell">
                            {getValue("tool")}
                        </Table.Cell>
                        <Table.Cell className="tableCell">
                            {getContributionPercentage("tool")}%
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row active>
                        <Table.Cell className="tableCell">Overhead</Table.Cell>
                        <Table.Cell className="tableCell">
                            {toFixed(total?.overhead)}
                        </Table.Cell>
                        <Table.Cell className="tableCell">
                            {getContributionPercentageByValue(total?.overhead)}%
                        </Table.Cell>
                    </Table.Row>
                    {!partInfo?.is_leaf && total?.children_cost_info ? (
                        <Table.Row active>
                            <Table.Cell className="tableCell">
                                Child Part Cost
                            </Table.Cell>
                            <Table.Cell className="tableCell">
                                {toFixed(total?.children_cost_info)}
                            </Table.Cell>
                            {
                                <Table.Cell className="tableCell">
                                    {getContributionPercentageByValue(
                                        total?.children_cost_info
                                    )}
                                    %
                                </Table.Cell>
                            }
                        </Table.Row>
                    ) : (
                        ""
                    )}
                    {Object.entries(cost?.overhead || {}).map(
                        ([key, value]) => (
                            <Table.Row key={key}>
                                <Table.Cell className="tableCell">
                                    {key}
                                </Table.Cell>
                                <Table.Cell className="tableCell">
                                    {toFixed(value)}
                                </Table.Cell>
                                <Table.Cell className="tableCell">
                                    {getContributionPercentageByValue(value)}%
                                </Table.Cell>
                            </Table.Row>
                        )
                    )}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell className="tableCell">
                            Total Cost (INR)
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan="2" className="tableCell">
                            {toFixed(perUnitCost)}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
}

export default CostTable;
