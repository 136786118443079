import { FEATURE } from "../actions/feature.actions";

const initialState = {
    featuresList: [],
    values: {},
    configs: [],
    featureInfo: {
        id: 0,
        group_name: "",
        feature_type: 0,
        feature_id: "",
        name: "",
    },
    error: null,
};

const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
});

const FeatureReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEATURE.FETCH_FEATURES_SUCCESS:
            return {
                ...initialState,
                featuresList: action.payload.sort((a, b) =>
                    collator.compare(a.feature_id, b.feature_id)
                ),
            };

        case FEATURE.FETCH_FEATURE_DETAILS_SUCCESS:
            const { details, parameter_relation, ...rest } = action.payload;
            return {
                ...initialState,
                values: details,
                configs:
                    parameter_relation?.filter(
                        config => config.data_type !== "table"
                    ) || [],
                featureInfo: rest,
            };

        default:
            return state;
    }
};

export { FeatureReducer, initialState as FeatureReducerInit };
