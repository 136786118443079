import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";

import scanner from "../../assets/scanner.svg";
import scanInfo from "../../assets/scan_qr_info.png";
import ScanQrModal from "../../Components/modals/ScanQrModal";
import { useBaseModal } from "../../hooks/useBaseModal";
import InfoModal from "../../Components/modals/InfoModal";
import { logout } from "../../redux/actions/auth.actions";

import "../../styles/_scanner.scss";
import { Button } from "semantic-ui-react";
import SearchModal from "../../Components/modals/SearchModal";

function Scanner() {
    const dispatch = useDispatch();

    const scanQrCode = useBaseModal();
    const infoModal = useBaseModal();
    const searchModal = useBaseModal();

    const handleLogout = () => dispatch(logout());

    return (
        <Fragment>
            <ScanQrModal
                isOpen={scanQrCode.isOpen}
                onClose={scanQrCode.close}
            />
            <InfoModal
                isOpen={infoModal.isOpen}
                onClose={infoModal.close}
                title="Info"
                basic
            >
                <img
                    src={scanInfo}
                    alt="scan info"
                    style={{
                        maxWidth: document.body.clientWidth - 50 + "px",
                    }}
                />
            </InfoModal>
            <SearchModal
                isOpen={searchModal.isOpen}
                onClose={searchModal.close}
            />
            <div className="scanner">
                <div className="scanner__icon-wrapper">
                    <div className="scanner__icon-wrapper__help-icon">
                        <Icon
                            name={"info circle"}
                            link
                            onClick={infoModal.open}
                        />
                    </div>
                    <div
                        className="scanner__icon-wrapper__logout-icon"
                        onClick={handleLogout}
                    >
                        <Icon name="power off" link color="red" />
                    </div>
                </div>
                <span className="scanner__page">
                    <span className="scanner__page__infobox"></span>
                    <span className="scanner__page__infoboxActive"></span>
                </span>

                <div className="scanner__image-container">
                    <div className="scannerIcon">
                        <img
                            src={scanner}
                            alt="error"
                            className="scanner__image"
                            onClick={scanQrCode.open}
                            width="200"
                            height="200"
                        />
                    </div>
                    <p style={{textAlign:'center'}}>Click on the QR for scanning part (Allow camera access for QR scanning)</p>
                </div>

                <div className="search-btn">
                    <Button type="button" onClick={searchModal.open}>
                        Search Part Number
                    </Button>
                </div>
                <div className="footer">
                    <p></p>
                </div>
            </div>
        </Fragment>
    );
}

export default Scanner;
