import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import bomdata from "../../assets/bomdatawhite.svg";
import feature from "../../assets/featurewhite.svg";
import cost from "../../assets/costwhite.svg";
import circuitdiagram from "../../assets/circuitdiagramwhite.svg";
import image from "../../assets/imagewhite.svg";
import scanner from "../../assets/scannerwhite.svg";
import scannerBlack from "../../assets/scanner.svg";
import Idea from "../../assets/idea-generationwhite.svg";

import "../../styles/_home.scss";
import InfoModal from "../../Components/modals/InfoModal";
import { useBaseModal } from "../../hooks/useBaseModal";
import {
    getSubsystemName,
    getBoundaryInfo,
} from "../../constants/info.constants";
import vaultService from "../../services/vault.service";
import { logout } from "../../redux/actions/auth.actions";
import { useDispatch } from "react-redux";
import { setError } from "../../redux/actions/error.actions";
import ScanToGetPartDetails from "../../Components/modals/ScanToGetPartDetails";
import GenerateIdeaModal from "../../Components/modals/GenerateIdeaModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../assets/loadergif.gif";

// import { getFeatures } from "../../redux/actions/feature.actions";
import {
    Table,
    Button,
    Form,
    FormField,
    Divider,
    FormTextArea,
    Icon,
    Dropdown,
} from "semantic-ui-react";
import uploadimage from "../../utils/uploadimage";
import IdeaTypeList from "../../constants/ClientIdea.constants";
import API from "../../services/api.service";

function Home() {
    const { id } = useParams();
    const InputRef = useRef();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedPart, setSelectedPart] = React.useState({});
    const [selectedVault, setSelectedVault] = useState("");
    const [isFetchingOptions, setIsFetchingOptions] = React.useState(false);
    const [searchPartsOptions, setSearchVaultsOptions] = React.useState([]);
    const timeout = useRef(null);
    const [partNumber, setPartNumber] = useState("");
    const [partName, setPartName] = useState("");
    const [partWeight, setPartWeight] = useState("");
    const [ideaType, setIdeaType] = useState("");
    const [currentScenario, setCurrentScenario] = useState("");
    const [scanResult, setScanResult] = useState({});
    const [competitionScenario, setCompetitionScenario] = useState("");
    const [ideaDescription, setIdeaDescription] = useState("");
    const InputRefSecond = useRef();
    const [tableData, setTableData] = useState([]);
    const [currentScenarioImage, setCurrentScenarioImage] = useState("");
    const [competitionScenarioImage, setCompetitionScenarioImage] =
        useState("");
    const [
        currentScenarioImageUploadLoader,
        setCurrentScenarioImageUploadLoader,
    ] = useState(false);
    const [
        competitionScenarioImageUploadLoader,
        setCompetitionScebarioImageUploadModal,
    ] = useState(false);
    const [isShowButton, setIsShowButton] = useState(false);
    const infoModal = useBaseModal();
    const QRModal = useBaseModal();
    const dispatch = useDispatch();

    const [vault, setVault] = useState();

    const GenerateIdeaModalOpen = useBaseModal();

    const sessionId = sessionStorage.getItem("sessionId");

    const fetchVault = async vaultId => {
        try {
            const data = await vaultService.get({ vaultId, sessionId });
            setVault(data);
        } catch (error) {
            dispatch(setError(error));
        }
    };

    const handleDropdown = (e, data) => {
        data.options.map(dataItem => {
            if (dataItem.value === data.value) {
                setSelectedPart(dataItem);
                setPartNumber(data.text);
                setSelectedVault(dataItem.value);
            }
        });
    };
    const handleLogout = () => dispatch(logout());

    const handleSuccessMessage = message => {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: "success-toast", // Optional custom class for styling
        });
    };

    const handleSearchChange = (e, DropdownOnSearchChangeData) => {
        if (DropdownOnSearchChangeData.searchQuery.length >= 7) {
            setIsFetchingOptions(true);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(
                () =>
                    vaultService
                        .searchByPartNumber(
                            DropdownOnSearchChangeData.searchQuery,
                            sessionStorage.getItem("sessionId")
                        )
                        .then(partNumber => {
                            const options = partNumber.map(p => ({
                                key: p.name,
                                text: p.part_no,
                                value: p.id,
                                weight: p.weight,
                            }));
                            setIsFetchingOptions(false);
                            setSearchVaultsOptions(options);
                        })
                        .catch(error => {
                            dispatch(setError(error));
                        }),
                1000
            );
        } else {
            setIsFetchingOptions(false);
        }
    };
    useEffect(() => {
        if (Object.keys(scanResult)?.length > 0) {
            setPartNumber(scanResult?.part_no);
            setPartName(scanResult?.name);
            setPartWeight(scanResult?.weight);
            setSelectedVault(scanResult?.id);
        }
    }, [scanResult]);
    useEffect(() => {
        if (Object.keys(selectedPart)?.length > 0) {
            setPartName(selectedPart?.key);
            setPartWeight(selectedPart?.weight);
        }
    }, [selectedPart]);

    const handleOnChange = e => {
        if (e.target.name === "name") {
            setName(e.target.value);
        }
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
        if (e.target.name === "phone_no") {
            setPhone(e.target.value);
        }
        if (e.target.name === "part_number") {
            setPartNumber(e.target.value);
        }
        if (e.target.name === "part_name") {
            setPartName(e.target.value);
        }
        if (e.target.name === "part_weight") {
            setPartWeight(e.target.value);
        }
        if (e.target.name === "idea_type") {
            setIdeaType(e.target.value);
        }
        if (e.target.name === "current_scenario") {
            setCurrentScenario(e.target.value);
        }
        if (e.target.name === "competition_scenario") {
            setCompetitionScenario(e.target.value);
        }
        if (e.target.name === "idea_description") {
            setIdeaDescription(e.target.value);
        }
    };
    const handleChange = (e, type) => {
        const file = e.target.files?.[0];
        uploadimage(
            file,
            type,
            setCurrentScenarioImage,
            setCompetitionScenarioImage,
            setCurrentScenarioImageUploadLoader,
            setCompetitionScebarioImageUploadModal
        );
        if (type === "current scenario") {
            setCurrentScenarioImageUploadLoader(true);
        } else {
            setCompetitionScebarioImageUploadModal(true);
        }
        // saveImage(file, id);
        // setDisable( false );
    };

    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        API.post("web_companion/comapanion_customer_idea_generation/", {
            name: name,
            email: email,
            phone: phone,
            vault_info: selectedVault,
            idea_type: ideaType,
            current_scenario: currentScenario,
            competitor_scenario: competitionScenario,
            idea_description: ideaDescription,
            current_scenario_image: currentScenarioImage,
            competitor_scenario_image: competitionScenarioImage,
        })
            .then(res => {
                setName("");
                setPhone("");
                setEmail("");
                setSelectedVault("");
                setIdeaType("");
                setCurrentScenario("");
                setCompetitionScenario("");
                setCurrentScenarioImage("");
                setCompetitionScenarioImage("");
                setCurrentScenarioImageUploadLoader(false);
                setCompetitionScebarioImageUploadModal(false);
                setScanResult({});
                setSelectedPart({});
                res.json();
                dispatch(setError("Idea created Successfully"));
                GenerateIdeaModalOpen.close();
            })
            .catch(err => console.log(err));
    };
    const browserFilehandler = () => {
        return InputRef?.current?.click();
    };
    const browserFilehandlerSecond = () => {
        return InputRefSecond?.current?.click();
    };

    const links = [
        { label: "Media", image: image, to: `/image/${id}` },
        { label: "BOM", image: bomdata, to: `/bomdata/${id}` },
        { label: "Features", image: feature, to: `/feature/${id}` },
        { label: "Costing", image: cost, to: `/cost/${id}` },
        {
            label: "Architecture",
            image: circuitdiagram,
            to: `/circuitdiagram/${id}`,
        },
        { label: "Ideas", image: Idea, to: `/idea/${id}` },
    ];

    useEffect(() => {
        fetchVault(id);
    }, [id]);

    const renderLink = link => (
        <Link exact to={link.to} key={link.label}>
            <div className="home__module">
                <div className="icondiv">
                    <img
                        src={link.image}
                        alt={link.label}
                        className="home__module-icon"
                    />
                </div>
                <span className="home__module-name">{link.label}</span>
            </div>
        </Link>
    );

    return (
        <Fragment>
            <InfoModal
                isOpen={infoModal.isOpen}
                onClose={infoModal.close}
                title={vault ? getSubsystemName(vault.abbreviation) : ""}
            >
                {vault && getBoundaryInfo(vault.abbreviation)}
            </InfoModal>
            <ScanToGetPartDetails
                isOpen={QRModal.isOpen}
                onClose={QRModal.close}
                setScanResult={setScanResult}
            />
            <GenerateIdeaModal
                isOpen={GenerateIdeaModalOpen.isOpen}
                onClose={GenerateIdeaModalOpen.close}
                title="xcPEP Idea Form"
                basic
            >
                <div>
                    <Form onSubmit={e => handleSubmit(e)}>
                        <FormField>
                            <label>
                                Name <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                placeholder="Name"
                                name="name"
                                required
                                onChange={e => handleOnChange(e)}
                            />
                        </FormField>
                        <FormField>
                            <label>
                                Email ID <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                name="email"
                                placeholder="Email id"
                                required
                                onChange={e => handleOnChange(e)}
                            />
                        </FormField>
                        <FormField>
                            <label>
                                Phone Number{" "}
                                <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                name="phone_no"
                                placeholder="Phone No."
                                onChange={e => handleOnChange(e)}
                                required
                            />
                        </FormField>
                        <Divider />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <label>Scan QR to Autofill Part Details</label>
                            <img
                                src={scannerBlack}
                                alt="error"
                                className="module-navigation__scan-icon"
                                onClick={() => QRModal.open()}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                        <Divider />
                        <FormField>
                            <label>
                                Part Number{" "}
                                <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <Dropdown
                                placeholder={
                                    Object.keys(scanResult)?.length > 0
                                        ? partNumber
                                        : "Select PartNumber"
                                }
                                fluid
                                search
                                selection
                                value={+selectedVault}
                                options={searchPartsOptions}
                                onSearchChange={handleSearchChange}
                                onChange={handleDropdown}
                                loading={isFetchingOptions}
                                selectOnBlur={false}
                            />
                        </FormField>
                        <FormField>
                            <label>
                                Part Name <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                name="part_name"
                                value={partName}
                                readOnly={true}
                                placeholder="part name"
                                onChange={e => handleOnChange(e)}
                                required
                            />
                        </FormField>
                        <FormField>
                            <label>
                                Part Weight{" "}
                                <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                                type="number"
                                name="part_weight"
                                value={partWeight}
                                readOnly={true}
                                placeholder="part weight."
                                onChange={e => handleOnChange(e)}
                                required
                            />
                        </FormField>
                        <FormField>
                            <label>
                                Select Idea types{" "}
                                <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <select
                                name="idea_type"
                                multiple=""
                                onChange={e => handleOnChange(e)}
                                value={ideaType}
                                class="ui fluid dropdown"
                                required
                            >
                                {IdeaTypeList?.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </FormField>
                        <FormField
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <FormTextArea
                                label="Current Scenario"
                                name="current_scenario"
                                required={true}
                                onChange={e => handleOnChange(e)}
                                placeholder="write current scenario..."
                                style={{ width: "20rem" }}
                            />
                            {!currentScenarioImageUploadLoader ? (
                                <Icon
                                    name="camera retro"
                                    link
                                    style={{
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                        color:
                                            currentScenarioImage?.length > 0
                                                ? "green"
                                                : "",
                                    }}
                                    onClick={() => browserFilehandler()}
                                />
                            ) : (
                                <div style={{ width: "3rem", height: "3rem" }}>
                                    <img src={loader} />{" "}
                                </div>
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                id="files"
                                name="files"
                                capture="user"
                                style={{ display: "none" }}
                                onChange={e =>
                                    handleChange(e, "current scenario")
                                }
                                ref={InputRef}
                                multiple
                            />
                        </FormField>
                        <FormField
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <FormTextArea
                                label="Competition Scenario"
                                name="competition_scenario"
                                required={true}
                                onChange={e => handleOnChange(e)}
                                placeholder="write competition scenario..."
                                style={{ width: "20rem" }}
                            />
                            {!competitionScenarioImageUploadLoader ? (
                                <Icon
                                    name="camera retro"
                                    link
                                    style={{
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                        color:
                                            competitionScenarioImage?.length > 0
                                                ? "green"
                                                : "",
                                    }}
                                    onClick={() => browserFilehandlerSecond()}
                                />
                            ) : (
                                <div style={{ width: "3rem", height: "3rem" }}>
                                    <img src={loader} />{" "}
                                </div>
                            )}
                            <input
                                type="file"
                                id="files"
                                accept="image/*"
                                name="files"
                                style={{ display: "none" }}
                                capture="user"
                                onChange={e =>
                                    handleChange(e, "competitor scenario")
                                }
                                ref={InputRefSecond}
                                multiple
                            />
                        </FormField>
                        <FormField>
                            <FormTextArea
                                label="Idea Description"
                                name="idea_description"
                                onChange={e => handleOnChange(e)}
                                placeholder="write idea description..."
                            />
                        </FormField>

                        <Button type="submit">Submit</Button>
                    </Form>
                </div>
            </GenerateIdeaModal>
            <div className="home">
                <div className="home__icon-wrapper">
                    <div className="home__icon-wrapper__help-icon">
                        <Icon
                            name={"help circle"}
                            link
                            onClick={infoModal.open}
                        />
                    </div>
                    <div className="home__icon-wrapper__help-icon">
                        <Button
                            style={{
                                backgroundColor: "#0b3f95",
                                color: "white",
                            }}
                            onClick={() => GenerateIdeaModalOpen.open()}
                        >
                            Generate Idea +
                        </Button>
                    </div>
                    <div
                        className="home__icon-wrapper__logout-icon"
                        onClick={handleLogout}
                    >
                        <Icon name="power off" color="red" link />
                    </div>
                </div>
                {/* <div className='home__info-icon'>
					<Icon name='help circle' link onClick={infoModal.open} />
				</div> */}
                <div className="home__moduleSec">
                    {links
                        .filter(module => vault?.module?.includes(module.label))
                        .map(renderLink)}
                </div>
                {/* <div className='home__logout-icon' onClick={handleLogout}>
					<Icon name='log out' link />
				</div> */}
                <div className="home__scan-icon">
                    <Link to="/scanner">
                        <img
                            src={scanner}
                            alt="error"
                            width="50px"
                            height="50px"
                        />
                    </Link>
                </div>
            </div>
        </Fragment>
    );
}

export default Home;
