import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import "../../styles/_image-category.scss";

function ImageCategory() {
    const { id } = useParams();

    return (
        <div className="moduleSec">
            <div className="image-category">
                <Link to={`/image/${id}/2`}>
                    <span className="image-category__category-text">
                        On Product
                    </span>
                </Link>

                <Link to={`/image/${id}/3`}>
                    <span className="image-category__category-text">
                        Teardown
                    </span>
                </Link>

                <Link to={`/image/${id}/4`}>
                    <span className="image-category__category-text">Other</span>
                </Link>
            </div>
        </div>
    );
}

export default ImageCategory;
