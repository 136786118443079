import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useRouteMatch } from 'react-router-dom';
import { getFeatures } from '../../redux/actions/feature.actions';
import { Table } from 'semantic-ui-react';

function FeaturesList() {
	const { id, level, type } = useParams();
	const isTopVault = useMemo(() => level === '1', [level]);
	const dispatch = useDispatch();
	const { url } = useRouteMatch();

	const features = useSelector(state => state.feature.featuresList);

	useEffect(() => {
		dispatch(
			getFeatures({
				featureType: type,
				isTopVault,
				vaultId: id,
				sessionId: sessionStorage.getItem('sessionId'),
			})
		);
	}, [id, isTopVault, type]);

	const renderRow = feature => (
		<Table.Row key={feature.id}>
			<Table.Cell
				className='tableCell'
				style={{ textDecoration: 'underline' }}>
				<Link to={`${url}/${feature.id}`}>{feature.feature_id}</Link>
			</Table.Cell>
			<Table.Cell className='tableCell'>{feature.group_name}</Table.Cell>
			<Table.Cell className='tableCell'>{feature.name}</Table.Cell>
		</Table.Row>
	);

	return (
		<div className='feature__list-container'>
			<Table celled unstackable className='feature__list-table'>
				<Table.Header>
					<Table.Row textAlign='center'>
						<Table.HeaderCell className='tableHeader'>
							Feature ID
						</Table.HeaderCell>
						<Table.HeaderCell className='tableHeader'>
							Group Name
						</Table.HeaderCell>
						<Table.HeaderCell className='tableHeader'>
							Feature Name
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>{features.map(renderRow)}</Table.Body>
			</Table>
		</div>
	);
}

export default FeaturesList;
