import React, { useEffect } from "react";
import HomeIcon from "@material-ui/icons/Home";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { Image } from "semantic-ui-react";

import cost from "../../assets/cost.svg";
import scanner from "../../assets/scanner.svg";
import LoadingImage from "../../assets/backgroundLazy.jpeg";
import { getCost } from "../../redux/actions/cost.actions";
import PartInfoTable from "./PartInfoTable";
import CostTable from "./CostTable";
import CostChart from "./CostChart";
import ImageLoader from "../../utils/ImageLoader";
import "../../styles/_module-navigation.scss";
import "../../styles/_cost.scss";

function Cost() {
    const { id } = useParams();
    const { url } = useRouteMatch();
    const dispatch = useDispatch();

    const imageUrl = useSelector(state => state.cost.image);

    useEffect(() => {
        dispatch(
            getCost({
                vaultId: id,
                sessionId: sessionStorage.getItem("sessionId"),
            })
        );
    }, []);

    return (
        <div className="cost">
            <div className="module-navigation">
                <Link exact to={`/home/${id}`}>
                    <HomeIcon className="module-navigation__home-icon" />
                </Link>

                <Link exact to={url}>
                    <img
                        src={cost}
                        alt="Costing"
                        className="module-navigation__module-icon"
                    />
                </Link>

                <Link exact to="/scanner">
                    <img
                        src={scanner}
                        alt="error"
                        className="module-navigation__scan-icon"
                    />
                </Link>
            </div>
            <div className="cost__main">
                <PartInfoTable />
                <div>
                    {imageUrl && (
                        <ImageLoader
                            src={imageUrl[0]?.image_url}
                            placeholderImg={LoadingImage}
                            alt={imageUrl[0]?.image_name}
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                            }}
                        />
                    )}
                </div>
                <CostChart />
                <CostTable />
                {/* {imageUrl?.map((item, index) => {
                    return (
                        
                    );
                })} */}
            </div>
        </div>
    );
}

export default Cost;
