import React from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createHashHistory } from "history";

import store from "./redux/store";
import AppRouter from "./AppRouter";
import Footer from "./Components/Footer";
import ErrorToast from "./Components/ErrorToast";

export const hashHistory = createHashHistory();

function App() {
    return (
        <Provider store={store}>
            <Router history={hashHistory}>
                <div
                    className="application"
                    style={{ height: document.body.clientHeight + "px" }}
                >
                    <div className="application__main">
                        <AppRouter />
                    </div>
                    <Footer />
                    <ErrorToast />
                </div>
            </Router>
        </Provider>
    );
}

export default App;
