import React from "react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import bomSelectors from "../../redux/selectors/bom.selectors";
import DataRow from "./DataRow";

function TableData({ config, values }) {
    const columnConfigs = useSelector(state =>
        bomSelectors.selectTableColumnConfigs(state, config.id)
    );

    return (
        <div className="bom__table-container">
            <Table celled unstackable className="bom__table">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan={3}>
                            {config.name}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {columnConfigs.map(config => (
                        <DataRow
                            key={config.id}
                            name={config.name}
                            unit={config.unit}
                            value={values[config.id]}
                        />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
}

export default TableData;
