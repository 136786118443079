import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import loadingImage from "../../assets/backgroundLazy.jpeg";
import NoData from "../../Components/NoData";
import { getRepoImages } from "../../redux/actions/imageRepository.actions";

import "../../styles/_images-view.scss";
import ImageLoader from "../../utils/ImageLoader";

function ImagesView () {
    const { id, category } = useParams();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState( false );
    const images = useSelector( state => state.imageRepository.images );

    useEffect( async () => {
        setLoader( true );
        try {
            await dispatch(
                getRepoImages( {
                    vaultId: id,
                    imageCategory: category,
                    sessionId: sessionStorage.getItem( "sessionId" ),
                } )
            );
        } catch ( err ) {
            setLoader( false );
        } finally {
            setLoader( false );
        }
    }, [id, category] );

    const renderImageCard = image => (
        <ImageLoader
            key={ image.id }
            src={ image.image_url_cdn }
            placeholderImg={ loadingImage }
            style={ {
                width: "100%",
                height: "100%",
                objectFit: "contain",
                margin: "10px",
            } }
        />
    );

    return (
        <div className="images-view">
            { !images.length && <NoData message="No images to display" /> }
            { !loader && images.map( renderImageCard ) }
            { loader && <>< ImageLoader
                key={ 1 }
                src={ loadingImage }
                placeholderImg={ loadingImage }
                style={ {
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    margin: "10px",
                } }
            />< ImageLoader
                    key={ 1 }
                    src={ loadingImage }
                    placeholderImg={ loadingImage }
                    style={ {
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        margin: "10px",
                    } }
                />< ImageLoader
                    key={ 1 }
                    src={ loadingImage }
                    placeholderImg={ loadingImage }
                    style={ {
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        margin: "10px",
                    } }
                />< ImageLoader
                    key={ 1 }
                    src={ loadingImage }
                    placeholderImg={ loadingImage }
                    style={ {
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        margin: "10px",
                    } }
                /></> }

        </div>
    );
}

export default ImagesView;
