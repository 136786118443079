import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from "../redux/actions/error.actions";
import Toast from "./Toast";

function ErrorToast() {
    const dispatch = useDispatch();
    const error = useSelector(state => state.error.message);

    const handleErrorExit = () => dispatch(clearError());

    return <Toast text={error} onExited={handleErrorExit} />;
}

export default ErrorToast;
