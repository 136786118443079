import costService from "../../services/cost.service";
import { setError } from "./error.actions";

export const COST = {
    FETCH_COST_SUCCESS: "cost/get/fulfilled",
};

const getCost = ( { vaultId, sessionId } ) => async dispatch => {
    try {
        const data = await costService.get( { vaultId, sessionId } );
        dispatch( {
            type: COST.FETCH_COST_SUCCESS,
            payload: data,
        } );
    } catch ( error ) {
        dispatch( setError( error ) );
    }
};

export { getCost };
