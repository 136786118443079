import { BOM } from "../actions/bom.actions";

const initialState = {
    values: {},
    configs: [],
};

const BomReducer = (state = initialState, action) => {
    switch (action.type) {
        case BOM.FETCH_BOM_SUCCESS:
            const [bom] = action.payload;
            // console.log( bom );
            if (!bom) return state;

            return {
                values: bom.details,
                configs: bom.parameter_relation,
                partno: bom.part_no,
            };

        default:
            return state;
    }
};

export { BomReducer, initialState as BomReducerInit };
