import API from "../services/api.service";

export default {
    get ( { vaultId, sessionId } ) {
        return API.get( "web_companion/comp/component_details/", {
            vault: vaultId,
            session: sessionId
        } ).then( API.toJSON );
    },
};
