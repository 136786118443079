import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import ImageComponent from '../../Components/ImageComponent';
import { getFeatureById } from '../../redux/actions/feature.actions';

function FeatureDetails() {
	const dispatch = useDispatch();
	const { id, featureId } = useParams();

	const values = useSelector(state => state.feature.values);
	const configs = useSelector(state => state.feature.configs);
	const featureInfo = useSelector(state => state.feature.featureInfo);

	useEffect(() => {
		dispatch(
			getFeatureById({
				featureId,
				vaultId: id,
				sessionId: sessionStorage.getItem('sessionId'),
			})
		);
	}, []);

	const renderRow = config => (
		<Table.Row key={config.id}>
			<Table.Cell className='tableCell'>{config.name}</Table.Cell>
			<Table.Cell className='tableCell'>
				{config.data_type === 'image' ? (
					<ImageComponent relPath={values[config.id]} />
				) : config.data_type === 'video' ? (
					<ImageComponent relPath={values[config.id]?.thumbnail} />
				) : (
					values[config.id]
				)}
			</Table.Cell>
		</Table.Row>
	);

	return (
		<div className='feature__details-container'>
			<Table celled unstackable className='feature__details-table'>
				<Table.Header>
					<Table.Row textAlign='center'>
						<Table.HeaderCell colSpan='3' className='tableHeader'>
							Feature Parameters
						</Table.HeaderCell>
					</Table.Row>
					<Table.Row textAlign='center'>
						<Table.HeaderCell className='tableHeader'>
							Parameter
						</Table.HeaderCell>
						<Table.HeaderCell className='tableHeader'>
							Value
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<Table.Row>
						<Table.Cell className='tableCell'>
							Feature Id
						</Table.Cell>
						<Table.Cell className='tableCell'>
							{featureInfo.feature_id}
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell className='tableCell'>
							Feature Name
						</Table.Cell>
						<Table.Cell className='tableCell'>
							{featureInfo.name}
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell className='tableCell'>
							Group Name
						</Table.Cell>
						<Table.Cell className='tableCell'>
							{featureInfo.group_name}
						</Table.Cell>
					</Table.Row>
					{configs.map(renderRow)}
				</Table.Body>
			</Table>
		</div>
	);
}

export default FeatureDetails;
